import { createApp } from 'vue';
import hljs from 'highlight.js/lib/core';
import xml from 'highlight.js/lib/languages/xml';
import hljsVuePlugin from '@highlightjs/vue-plugin';

hljs.registerLanguage('xml', xml);

const app = createApp();
app.use(hljsVuePlugin);
app.mount('#section-wrapper');
